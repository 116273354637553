<template>
  <div class="grid">
    <div class="col-12 lg:col-6 xl:col-2"></div>
    <i class="pi pi-arrow-right arrow-right" />
    <div class="col-12 lg:col-6 xl:col-2">
      <div class="overview-box canceled clickable" @click="props.navigate(2)">
        <i class="overview-icon pi pi-times-circle" />
        <span class="overview-title">Cancelados</span>
        <div class="overview-numbers">{{ canceled.data.length }}</div>
        <div class="overview-subinfo">{{ canceled.percent }}% este mes</div>
      </div>
    </div>
    <i class="pi pi-arrow-right arrow-right" style="opacity: 0%" />
    <div class="col-12 lg:col-6 xl:col-2"></div>
    <i class="pi pi-arrow-right arrow-right" style="opacity: 0%" />
    <div class="col-12 lg:col-6 xl:col-2"></div>
    <i class="pi pi-arrow-right arrow-right" />
    <div class="col-12 lg:col-6 xl:col-2">
      <div class="overview-box failed clickable" @click="props.navigate(7)">
        <i class="overview-icon pi pi-exclamation-circle" />
        <span class="overview-title">Fallidos</span>
        <div class="overview-numbers">{{ failed.data.length }}</div>
        <div class="overview-subinfo">{{ failed.percent }}% este mes</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed } from "vue";
import { getStatusData } from "@/services/shipments";
const props = defineProps({
  shipments: Array,
  navigate: Function,
});

const canceled = computed(() => {
  return getStatusData(props.shipments, "cancelado");
});

const failed = computed(() => {
  return getStatusData(props.shipments, "fallido");
});
</script>

<style scoped>
.canceled {
  background-color: #bdbdbd;
}

.failed {
  background-color: #8e6161;
}
.overview-icon {
  color: #eff2f5;
}
.overview-subinfo {
  border-color: #c1c3c6;
  color: #eff2f5;
}
.arrow-right {
  margin-top: 50px;
  color: #0000e2;
}
.clickable {
  cursor: pointer;
}
</style>
