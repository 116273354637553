<template>
  <div class="layout-dashboard">
    <Toast />
    <ConfirmDialog />
    <ProgressSpinner
      v-if="loading"
      style="width: 50px; height: 50px; margin-top: 20px; margin-left: 40%"
      class="spinner"
      strokeWidth="8"
      fill="var(--surface-ground)"
      animationDuration=".5s"
    />
    <FirstLine :shipments="shipments" :navigate="navigate" v-if="!loading" />
    <SecondLine :shipments="shipments" :navigate="navigate" v-if="!loading" />
    <ThirdLine :shipments="shipments" :navigate="navigate" v-if="!loading" />
  </div>
</template>
<script setup>
import { ref } from "vue";
import { getShipments } from "@/api/shipment";
import { readUserData } from "@/services/user";
import { findByUser } from "@/api/customer";
import { useToast } from "primevue/usetoast";
import FirstLine from "./FirstLine.vue";
import SecondLine from "./SecondLine.vue";
import ThirdLine from "./ThirdLine.vue";
import { getNowShipments } from "@/services/shipments";
import { useRouter } from "vue-router";
import ProgressSpinner from "primevue/progressspinner";
import ConfirmDialog from "primevue/confirmdialog";
import { useConfirm } from "primevue/useconfirm";
import { pendingConfigMessage } from "@/constants";
const router = useRouter();

const toast = useToast();
const user = readUserData();
const loading = ref(true);
const shipments = ref([]);
let customer = ref({});
const confirm = useConfirm();

const getShipmentsData = async () => {
  const { data } = await getShipments(customer.value.id);
  shipments.value = getNowShipments(data);
};

const navigate = (tab) => {
  router.push(`/shipments/?tab=${tab}`);
};

const getCustomerData = async () => {
  try {
    const response = await findByUser(user.id);
    customer.value = response.data;
  } catch (err) {
    showToast("error", "Error", "Ha ocurrido un error al cargar los datos");
  }
};

const showToast = (severity, summary, detail) => {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 7000,
  });
};

const startComponent = async () => {
  loading.value = true;
  await getCustomerData();
  if (!customer.value.id) {
    needsConfiguration();
    return;
  }
  await getShipmentsData();
  loading.value = false;
};

const needsConfiguration = () => {
  confirm.require({
    message: pendingConfigMessage,
    header: "Configuración pendiente",
    icon: "pi pi-info-circle",
    acceptLabel: "Ok",
    accept: () => {
      router.push("/config");
    },
  });
};

startComponent();
</script>
<style scoped>
.arrow-right {
  margin-top: 50px;
  color: #0000e2;
}
.overview-icon {
  color: #bbdefb;
}

.overview-subinfo {
  border-color: #bbdefb;
  color: #bbdefb;
}
</style>
