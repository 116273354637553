<template>
  <div class="grid">
    <div class="col-12 lg:col-6 xl:col-2">
      <div class="overview-box pendings clickable" @click="props.navigate(1)">
        <i class="overview-icon pi pi-check-square"></i>
        <span class="overview-title">Pendientes</span>
        <div class="overview-numbers">{{ pendings.data.length }}</div>
        <div class="overview-subinfo">{{ pendings.percent }}% este mes</div>
      </div>
    </div>
    <i class="pi pi-arrow-right arrow-right" />
    <div class="col-12 lg:col-6 xl:col-2">
      <div
        class="overview-box preparation clickable"
        @click="props.navigate(3)"
      >
        <i class="overview-icon pi pi-inbox"></i>
        <span class="overview-title">Preparación</span>
        <div class="overview-numbers">{{ preparation.data.length }}</div>
        <div class="overview-subinfo">{{ preparation.percent }}% este mes</div>
      </div>
    </div>
    <i class="pi pi-arrow-right arrow-right" />
    <div class="col-12 lg:col-6 xl:col-2">
      <div class="overview-box courier clickable" @click="props.navigate(4)">
        <i class="overview-icon pi pi-box"></i>
        <span class="overview-title">Transportista</span>
        <div class="overview-numbers">{{ courier.data.length }}</div>
        <div class="overview-subinfo">{{ courier.percent }}% este mes</div>
      </div>
    </div>
    <i class="pi pi-arrow-right arrow-right" />
    <div class="col-12 lg:col-6 xl:col-2">
      <div class="overview-box truck clickable" @click="props.navigate(5)">
        <i class="overview-icon pi pi-send"></i>
        <span class="overview-title">Ruta</span>
        <div class="overview-numbers">{{ truck.data.length }}</div>
        <div class="overview-subinfo">{{ truck.percent }}% este mes</div>
      </div>
    </div>
    <i class="pi pi-arrow-right arrow-right" />
    <div class="col-12 lg:col-6 xl:col-2">
      <div class="overview-box delivered clickable" @click="props.navigate(6)">
        <i class="overview-icon pi pi-home"></i>
        <span class="overview-title">Entregados</span>
        <div class="overview-numbers">{{ delivered.data.length }}</div>
        <div class="overview-subinfo">{{ delivered.percent }}% este mes</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed } from "vue";
import { getStatusData } from "@/services/shipments";

const props = defineProps({
  shipments: Array,
  navigate: Function,
});

const pendings = computed(() => {
  return getStatusData(props.shipments, "pendiente");
});

const preparation = computed(() => {
  return getStatusData(props.shipments, "preparacion");
});

const courier = computed(() => {
  return getStatusData(props.shipments, "transportista");
});

const truck = computed(() => {
  return getStatusData(props.shipments, "ruta");
});

const delivered = computed(() => {
  return getStatusData(props.shipments, "entregado");
});
</script>

<style scoped>
.arrow-right {
  margin-top: 50px;
  color: #0000e2;
}
.pendings {
  background-color: #7fbef3;
}
.preparation {
  background-color: #55acf3;
}
.courier {
  background-color: #3c9ff0;
}
.truck {
  background-color: #1e92f1;
}
.delivered {
  background-color: #0585ed;
}
.overview-icon {
  color: #bbdefb;
}
.overview-subinfo {
  border-color: #bbdefb;
  color: #bbdefb;
}
.clickable {
  cursor: pointer;
}
</style>
