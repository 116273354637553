<template>
  <div class="grid">
    <div class="col-12 lg:col-6 xl:col-2"></div>
    <i class="pi pi-arrow-right arrow-right" style="opacity: 0%" />
    <div class="col-12 lg:col-6 xl:col-2"></div>
    <i class="pi pi-arrow-right arrow-right" style="opacity: 0%" />
    <div class="col-12 lg:col-6 xl:col-2"></div>
    <i class="pi pi-arrow-right arrow-right" style="opacity: 0%" />
    <div class="col-12 lg:col-6 xl:col-2"></div>
    <i class="pi pi-arrow-right arrow-right" />
    <div class="col-12 lg:col-6 xl:col-2">
      <div class="overview-box aborted clickable" @click="props.navigate(8)">
        <i class="overview-icon pi pi-times-circle" />
        <span class="overview-title">No Entregados</span>
        <div class="overview-numbers">{{ aborted.data.length }}</div>
        <div class="overview-subinfo">{{ aborted.percent }}% este mes</div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { defineProps, computed } from "vue";
import { getStatusData } from "@/services/shipments";
const props = defineProps({
  shipments: Array,
  navigate: Function,
});

const aborted = computed(() => {
  return getStatusData(props.shipments, "devuelto");
});
</script>

<style scoped>
.aborted {
  background-color: #8e2323;
}
.overview-icon {
  color: #eff2f5;
}
.overview-subinfo {
  border-color: #c1c3c6;
  color: #eff2f5;
}
.arrow-right {
  margin-top: 50px;
  color: #0000e2;
}
.clickable {
  cursor: pointer;
}
</style>
